import React from "react"
import { Box, Container, Flex, Grid } from "@chakra-ui/core"
import Header from "../components/header"
import Footer from "../components/footer"
import PageHeader from "../components/page-header"
import SiteMetadata from "../components/site-metadata"

const Layout = ({ children, data, id }) => {
  return (
    <Grid
      templateRows="auto 1fr auto"
      templateAreas="'header' 'body' 'footer'"
      minH="100%"
      height="100vh"
      id={id}
    >
      <SiteMetadata />
      <Header area="header" />
      <Box as="main" pt="32">
        <PageHeader data={data.page} type="post" />
        <Container maxW="md">
          <Flex>
            <Box mt={10} mb={100} flex="1">
              {children}
            </Box>
          </Flex>
        </Container>
      </Box>
      <Footer area="footer" />
    </Grid>
  )
}

export default Layout

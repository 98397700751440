import { graphql, useStaticQuery } from "gatsby"

export default function useSiteMetadata() {
  const { wp } = useStaticQuery(graphql`
    query SiteMetadata {
      wp {
        allSettings {
          generalSettingsTitle
          generalSettingsUrl
        }
        themeOptions {
          gtagCode
          gtagCodeBody
          blogSlug
          linkedinUrl
          twitterUrl
        }
      }
    }
  `)
  return wp
}

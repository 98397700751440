import React from "react"
import { Box } from "@chakra-ui/core"
import BackgroundImage from "gatsby-background-image"

const Cover = ({ backgroundImage }) => {
  return (
    <>
      <Box bg="red.500">12345</Box>
      <BackgroundImage
        fluid={backgroundImage?.localFile.childImageSharp.fluid}
        w="100vw"
        pos="relative"
        left="50%"
        right="50%"
        marginLeft="-50vw"
        marginRight="-50vw"
      ></BackgroundImage>
    </>
  )
}

export default Cover

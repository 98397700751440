import React from "react"
import { Box, Flex, Text } from "@chakra-ui/core"
import { StylesProvider, useMultiStyleConfig, useStyles } from "@chakra-ui/core"
import { Icon } from "../../utils/icons"
import { CreateLocalLinkFromUrl } from "../../utils/create-local-link"
import UniversalLink from "../../utils/UniversalLink"
import { useSiteUrl } from "../../hooks/use-site-url"

function StyledButton(props) {
  const { size, variant, children, ...rest } = props

  const styles = useMultiStyleConfig("Button", { size, variant })

  return (
    <Box sx={styles.button} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

function StyledIcon(props) {
  const { size, variant } = props

  const styles = useMultiStyleConfig("Button", { size, variant })

  return <Icon sx={styles.icon} {...props} />
}

function Label(props) {
  const styles = useStyles()
  return <Box sx={styles.label} {...props} />
}

function Iconbox(props) {
  const styles = useStyles()
  return <Box sx={styles.iconbox} {...props} />
}

function Link(props) {
  const { children, item } = props
  const { wp } = useSiteUrl()
  const wordPressUrl = wp.generalSettings.url
  return (
    <UniversalLink to={CreateLocalLinkFromUrl(item, wordPressUrl)}>
      {children}
    </UniversalLink>
  )
}

const Button = ({ text, icon, link }) => {
  return (
    <StyledButton as="button">
      <Link item={link}>
        <Flex justify="center" align="center">
          {icon !== "none" && (
            <Iconbox>
              <StyledIcon icon={icon} />
            </Iconbox>
          )}
          <Text as="span">{text && <Label>{text}</Label>}</Text>
        </Flex>
      </Link>
    </StyledButton>
  )
}

export default Button

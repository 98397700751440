// src/components/AllLayouts.js

import React from "react"
import Card from "../acf-blocks/Card"
import Button from "../acf-blocks/Button"
import Wysiwyg from "../acf-blocks/Wysiwyg"
import Hubspot from "../acf-blocks/Hubspot"
import Cover from "../acf-blocks/Cover"
import AccordionBlock from "../acf-blocks/AccordionBlock"
import CardRepeater from "../acf-blocks/CardRepeater"
import StatsRepeater from "../acf-blocks/StatsRepeater"

const AllBlocks = ({ blockData, context }) => {
  const blockType = blockData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => (
    <div>In AllBlocks the mapping of this block is missing: {blockType}</div>
  )

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const blocks = {
    page_Pagebuilder_Layouts_Herolayout_BlockItems_Card: Card,
    page_Pagebuilder_Layouts_Herolayout_BlockItems_Button: Button,
    page_Pagebuilder_Layouts_Pagelayout_BlockItems_Button: Button,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Thirds_columns_BlockItems_Button: Button,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Half_columns_BlockItems_Button: Button,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Fullwidth_BlockItems_Button: Button,
    page_Pagebuilder_Layouts_Pagelayout_BlockItems_Wysiwyg: Wysiwyg,
    post_Pagebuilder_Layouts_Pagelayout_BlockItems_Wysiwyg: Wysiwyg,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Thirds_columns_BlockItems_Wysiwyg: Wysiwyg,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Half_columns_BlockItems_Wysiwyg: Wysiwyg,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Fullwidth_BlockItems_Wysiwyg: Wysiwyg,
    page_Pagebuilder_Layouts_Pagelayout_BlockItems_Hubspot: Hubspot,
    post_Pagebuilder_Layouts_Pagelayout_BlockItems_Hubspot: Hubspot,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Thirds_columns_BlockItems_Hubspot: Hubspot,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Half_columns_BlockItems_Hubspot: Hubspot,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Fullwidth_BlockItems_Hubspot: Hubspot,
    page_Pagebuilder_Layouts_Pagelayout_BlockItems_Cover: Cover,
    page_Pagebuilder_Layouts_Pagelayout_BlockItems_Accordion: AccordionBlock,
    page_Pagebuilder_Layouts_Sectionlayout_BlockItems_CardRepeater: CardRepeater,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Fullwidth_BlockItems_CardRepeater: CardRepeater,
    page_Pagebuilder_Layouts_Sectionlayout_BlockItems_StatsRepeater: StatsRepeater,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Fullwidth_BlockItems_StatsRepeater: StatsRepeater,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Half_columns_BlockItems_StatsRepeater: StatsRepeater,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Thirds_columns_BlockItems_StatsRepeater: StatsRepeater,
    page_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = blocks[blockType]
    ? blocks[blockType]
    : blocks["page_default"]

  return <ComponentTag {...blockData} context={context} />
}

export default AllBlocks

import React from "react"
import {
  Box,
  Image,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
  useDisclosure,
} from "@chakra-ui/core"
import { Link } from "gatsby"
import Menu from "../navigation/menu"
import logoUrl from "../../assets/images/vivvo_converge_logo_colour.svg"
import { Squash as Hamburger } from "hamburger-react"

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Box display={["block", "block", "block", "none"]}>
        <Hamburger
          toggled={isOpen}
          toggle={onOpen}
          rounded
          label="Show menu"
        ></Hamburger>
      </Box>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen} zIndex="50">
        <DrawerOverlay>
          <DrawerContent bg="white">
            <Box pt={8}>
              <DrawerHeader>
                <Link to="/">
                  <Image
                    src={logoUrl}
                    w={[32, 32, 48]}
                    minW={[32, 32, 48]}
                    objectFit="cover"
                    ml={[2, 2, 8]}
                  />
                </Link>
              </DrawerHeader>
              <DrawerBody>
                <Menu menulocation="mobile" variant="mobile" />
              </DrawerBody>
            </Box>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

export default Sidebar

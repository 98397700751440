import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import Stat from "../Stat"

const renderBgColor = (param) => {
  switch (param) {
    case "green":
      return "green.500"
    case "yellow":
      return "yellow.600"
    case "blue":
      return "blue.500"
    default:
      return "green.500"
  }
}

const renderTextColor = (param) => {
  switch (param) {
    case "green":
      return "white"
    case "yellow":
      return "white"
    case "blue":
      return "white"
    default:
      return "white"
  }
}

const StatRepeater = ({ stats, alignment, content, attributes }) => {
  return (
    <>
      <Flex
        direction={alignment === true ? ["column", "column", "row"] : "column"}
      >
        {stats.map((statRepeater, i) => {
          const color = statRepeater.colorChoice
          return (
            <Flex
              bg={`${renderBgColor(color)}`}
              color={`${renderTextColor(color)}`}
              direction={
                i === 0
                  ? "column"
                  : alignment === true
                  ? "column"
                  : "column-reverse"
              }
              justify="space-between"
              align="start"
              p={10}
              py={12}
              key={i}
              w={alignment === true ? ["100%", "100%", "50%"] : "100%"}
            >
              <Flex
                direction={["column", "row"]}
                justify={["center", "space-between"]}
                align="space-between"
                w="100%"
              >
                {statRepeater.statRepeater.map((statRepeater, k) => {
                  return (
                    <Stat
                      key={k}
                      caption={statRepeater.caption}
                      value={statRepeater.value}
                      color={color}
                    />
                  )
                })}
              </Flex>
              <Box
                fontSize="3xl"
                fontWeight="300"
                lineHeight="shorter"
                mt={i === 0 ? "6" : ""}
                mb={i === 0 ? "0" : alignment === true ? "0" : "6"}
              >
                {statRepeater.content}
              </Box>
            </Flex>
          )
        })}
      </Flex>
      {attributes && (
        <Box
          fontSize="sm"
          mt="2"
          color="gray.500"
          dangerouslySetInnerHTML={{ __html: attributes }}
        ></Box>
      )}
    </>
  )
}

export default StatRepeater

import React from "react"
import { graphql } from "gatsby"
import PageLayoutTemplate from "../../page-layout-templates/base"

export default ({ data }) => <PageLayoutTemplate data={data} />

export const query = graphql`
  query post($id: String!) {
    page: wpPost(id: { eq: $id }) {
      id
      date
      uri
      title
      content
      nodeType
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
      pageBuilder {
        layouts {
          ...PostPageLayout
          ...PostSeparator
        }
      }
    }
  }
`

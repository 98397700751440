import React, { useEffect } from "react"
import { Box } from "@chakra-ui/core"

const Hubspot = ({ portalidcode, formidcode }) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalidcode,
          formId: formidcode,
          target: "#hubspotForm",
        })
      }
    })
  })

  return <Box id="hubspotForm"></Box>
}

export default Hubspot

import React from "react"
import { CreateLocalLink } from "../../utils/create-local-link"
import { flatListToHierarchical } from "../../utils/flat-to-hierarchical"
import UniversalLink from "../../utils/UniversalLink"
import {
  Box,
  Container,
  Flex,
  StackDivider,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/core"

import { useMenus } from "../../hooks/use-menus"

const Breadcrumb = ({ data, depth }) => {
  return (
    <Container bg="green.100" maxW="none" pl="0" pr="0">
      <Container maxW="xl" w="100%">
        <Flex w="100%">
          <Box width="sidebar" d={["none", "none", "block"]}></Box>
          <Box d={["none", "none", "block"]}>
            <HStack py="4" divider={<Text border="none">/</Text>}>
              {data.map((m) => {
                return (
                  <Box key={m.id}>
                    <MenuLink item={m} depth={depth} />
                  </Box>
                )
              })}
            </HStack>
          </Box>
        </Flex>
      </Container>
    </Container>
  )
}

const MenuLink = ({ item, depth }) => {
  const { wp } = useMenus()
  const wordPressUrl = wp.generalSettings.url
  return (
    <Text
      d="inline"
      fontSize="xl"
      fontWeight={item.currentPage ? "400" : "300"}
      color={item.currentPage ? "blue.500" : "green.500"}
    >
      <UniversalLink to={CreateLocalLink(item, wordPressUrl)}>
        {item.label}{" "}
      </UniversalLink>
    </Text>
  )
}

const MenuBox = ({ data, depth = 0 }) => {
  return (
    <VStack
      as="nav"
      borderTop={1}
      borderTopColor="green.500"
      divider={depth === 0 ? <StackDivider borderColor="green.500" /> : ""}
      spacing={2}
      align="stretch"
      pos={depth === 0 ? "relative" : "relative"}
      fontWeight="400"
      fontSize="md"
      shouldWrapChildren="true"
    >
      {data.map((m) => {
        const { children = [] } = m
        return (
          <Box
            pl={depth === 0 ? 0 : 0}
            key={m.id}
            color={depth === 0 ? "primary" : "secondary"}
            fontWeight={depth === 0 ? "500" : "400"}
          >
            {children.length > 0 ? (
              <Box>
                <MenuLink item={m} depth={depth} />
                <MenuBox data={children} depth={depth + 1} />
              </Box>
            ) : (
              <Box>
                <MenuLink item={m} depth={depth} />
              </Box>
            )}
          </Box>
        )
      })}
    </VStack>
  )
}

export default (props) => {
  const { id } = props
  const { allWpMenu } = useMenus()
  const wpMenu = allWpMenu.nodes.filter(
    (node) => node.slug === "primary-menu"
  )[0]

  const hierarchicalList = flatListToHierarchical(wpMenu?.menuItems.nodes)

  let submenu = []
  let path = []

  path.push({
    id: "",
    label: "Home",
    url: "/",
  })

  for (var main of hierarchicalList) {
    let string = JSON.stringify(main)
    if (string.includes(`${id}`)) {
      /**
       * we have the correct branch, now we need to find the stem that contains the current page
       * **/

      let node = main.connectedNode.node

      if (node.id === id) {
        node.active = true
        node.currentPage = true
      }
      node.label = main.label
      node.url = node.uri
      path.push(node)

      if (main.children) {
        for (var branch of main.children) {
          let string = JSON.stringify(branch)
          if (string.includes(`${id}`)) {
            node = branch.connectedNode.node
            if (node.id === id) {
              node.active = true
              node.currentPage = true
            }
            node.label = branch.label
            node.url = node.uri
            path.push(node)

            if (branch.children) {
              for (var stem of branch.children) {
                let string = JSON.stringify(stem)
                if (string.includes(`${id}`)) {
                  node = stem.connectedNode.node
                  if (node.id === id) {
                    node.active = true
                    node.currentPage = true
                  }

                  node.label = stem.label
                  node.url = node.uri
                  path.push(node)
                }
              }
            }
          }
        }
        //submenu = main.children

        break
      }
    }
  }

  return !!wpMenu &&
    !!wpMenu.menuItems &&
    !!wpMenu.menuItems.nodes &&
    !!submenu ? (
    <Breadcrumb data={path} />
  ) : null
}

import React from "react"
import AllBlocks from "../acf-blocks/AllBlocks"

export const BlocksBuilder = ({ blocks, context }) => {
  return blocks.map((block, index) => {
    return (
      <AllBlocks
        key={index}
        index={index}
        blockData={block}
        context={context}
      />
    )
  })
}

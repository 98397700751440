export default {
  // The styles all headings have in common
  baseStyle: {
    //bg: "red.500",
    transform: "translate3d(0, 0, 0)",
    transition: "0.1s all ease-out",
    "&.hidden": {
      //boxShadow: "none",
      transform: "translate3d(0, -100%, 0)",
    },
  },
}

import React from "react"
import { Box } from "@chakra-ui/core"
import { BlocksBuilder } from "../../components/PageBuilder"
//import Img from "gatsby-image"
//import BackgroundImage from "gatsby-background-image"

const Page = ({ blockItems }) => {
  return (
    <Box pos="relative">
      {!!blockItems && <BlocksBuilder blocks={blockItems} />}
    </Box>
  )
}

export default Page

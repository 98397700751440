import React from "react"
import { CreateLocalLink } from "../../utils/create-local-link"
import UniversalLink from "../../utils/UniversalLink"
import { Text } from "@chakra-ui/core"
import { useMenus } from "../../hooks/use-menus"

const MenuLink = ({ item, depth, size }) => {
  const { wp } = useMenus()
  const wordPressUrl = wp.generalSettings.url
  return (
    <UniversalLink to={CreateLocalLink(item, wordPressUrl, "/insights")}>
      <Text
        as="span"
        d="inline"
        dangerouslySetInnerHTML={{ __html: item.label }}
      ></Text>
    </UniversalLink>
  )
}

export default MenuLink

import React from "react"
import { Heading, Box } from "@chakra-ui/core"
import Layout from "../layouts/page"

export default () => (
  <Layout>
    <Box>
      <Heading as="h1" size="xl">
        Looks like that page couldn't be found, sorry.
      </Heading>
    </Box>
  </Layout>
)

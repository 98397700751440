export default {
  parts: ["card", "iconbox", "title", "content"],
  baseStyle: {
    card: {
      bg: "green.500",
      pos: "relative",
      mt: "4",
      clipPath:
        "polygon(100% 0, 100% calc(100% - 70px), calc(100% - 70px) 100%, 0 100%, 0 0)",
      fontWeight: "300",
      mb: "6",
      "&:before": {
        display: "block",
        content: '""',
        width: "100%",
        paddingTop: ["95%", "55%"],
      },
    },
    title: {
      whiteSpace: ["normal", "normal"],
      fontSize: "2xl",
      fontWeight: "400",
      lineHeight: "none",
      color: "yellow.400",
      mb: "2",
    },
    iconbox: {
      color: "white",
      mb: [4, 0],
    },
    content: { fontSize: "xl", lineHeight: "shorter", color: "white" },
  },
  sizes: {
    sm: {
      card: {
        maxW: "400px",
      },
    },
    hero: {
      card: {
        maxW: "400px",
      },
    },
  },
  variants: {
    outline: {
      bg: "transparent",
    },
  },
}

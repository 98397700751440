import React from "react"
import { Box, Flex, Image, Text } from "@chakra-ui/core"
import { StylesProvider, useMultiStyleConfig, useStyles } from "@chakra-ui/core"
import { Link } from "gatsby"
import Menu from "./navigation/menu"
import logoUrl from "../assets/images/vivvo_converge_logo.svg"

function StyledFooter(props) {
  const { size, variant, children, ...rest } = props

  const styles = useMultiStyleConfig("Footer", { size, variant })

  return (
    <Box sx={styles} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

function Deco(props) {
  const styles = useStyles()
  return <Box sx={styles.deco} {...props} />
}

const Footer = ({ data, className }) => {
  return (
    <StyledFooter as="footer">
      <Deco className={className} bg="gray.800" height={20} mt={-20}></Deco>
      <Box px={[8, 8, 16]} py={12} pt={32} bg="gray.800">
        <Flex
          justify="space-between"
          wrap="wrap"
          alignItems={["flex-start"]}
          justifyContent={["flex-end", "flex-end", "space-between"]}
        >
          <Box
            display={{
              base: "none",
              md: "block",
            }}
          >
            <Menu menulocation="footer" variant="dark" />
          </Box>
          <Flex w="10"></Flex>
          <Flex
            alignItems={["flex-start"]}
            justifyContent={["flex-end", "flex-end", "flex-start"]}
          >
            <Link to="/">
              <Image color="white" src={logoUrl} w={48} minW={48} />
            </Link>
            <Text color="white" fontSize="3xl" lineHeight="1rem" ml="1">
              &#8482;
            </Text>
          </Flex>
        </Flex>
      </Box>
    </StyledFooter>
  )
}

export default Footer

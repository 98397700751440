import theme from "@chakra-ui/theme"

export const fontsConfig = {
  body: `"Rubik",${theme.fonts.body}`,
  heading: `"Rubik",${theme.fonts.heading}`,
}
export const fontSizes = {
  "3xl": "24px",
  "4xl": "38px",
  "5xl": "50px",
  "6xl": "60px",
  "7xl": "70px",
  "8xl": "80px",
}

export const colors = {
  blue: {
    500: "#04496B",
  },
  gray: {
    50: "#F7F7F7",
    100: "#E1E1E1",
    200: "#CFCFCF",
    300: "#B1B1B1",
    400: "#9E9E9E",
    500: "#7E7E7E",
    600: "#626262",
    700: "#515151",
    800: "#3B3B3B",
    900: "#222222",
  },
  green: {
    50: "#eef3f0",
    100: "#dfede5",
    300: "#8baf9a",
    400: "#4b858e",
    500: "#3b737c",
  },
  yellow: {
    100: "#f1f2df",
    300: "#cacc8f",
    400: "#d0d37e",
    500: "#c3cc08",
    600: "#a4a679",
  },
  primary: "#04496b",
  secondary: "#3b737c",
  gradient: {
    hero:
      "linear-gradient(to right bottom, rgba(139,175,154, 0.75) 99%, rgba(70,123,128, 0.75) 47%, rgba(8,72,106, 0.75) 1%)",
  },
}

export const sizes = {
  "1/3": "33%",
  "1/2": "50%",
  "2/3": "66%",
  "7/12": "58.33%",
  xxl: "87rem",
  xxlhalf: "43.5rem",
  sidebar: "22rem",
  cardw: "28rem",
  cardwm: "26rem",
  cardh: "16rem",
}

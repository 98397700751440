const gradientDarkness = (gradient) => {
  if (gradient === "Full") {
    return 51
  }
  if (gradient === "coverleft") {
    return 51
  }
  if (gradient === "coverright") {
    return 51
  }
  if (gradient === "covergray") {
    return 51
  }
  if (gradient === "Light") {
    return 20
  }
  if (gradient === "Gray") {
    return 10
  }
  return 0
}

export const backgroundDarkness = (gradient, darkness) => {
  const gradientDarknessval = gradientDarkness(gradient)
  let darknessVal = 0
  darknessVal += gradientDarknessval
  if (darkness) {
    darknessVal += parseInt(darkness)
  }

  if (darknessVal > 50) {
    return "dark"
  }

  return "light"
}

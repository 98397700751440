import React from "react"
import { Box } from "@chakra-ui/core"
import { useStyleConfig } from "@chakra-ui/core"
import { BlocksBuilder } from "../../../components/PageBuilder"

function StyledFullwidth(props) {
  const { size, variant, ...rest } = props

  const styles = useStyleConfig("Fullwidth", { size, variant })

  return <Box sx={styles} {...rest} />
}

const Fullwidth = ({ blockItems, bgclass }) => {
  return (
    <StyledFullwidth
      pos="relative"
      px={[8, 8, 16]}
      className={`fullw ${bgclass}`}
    >
      {!!blockItems && <BlocksBuilder blocks={blockItems} />}
    </StyledFullwidth>
  )
}
export default Fullwidth

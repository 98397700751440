export const renderGradient = (param) => {
  switch (param) {
    case "Full":
      return "linear-gradient(to right bottom, rgba(8,72,106, 0.75) 1%, rgba(139,175,154, 0.75)  47%, rgba(70,123,128, 0.75) 99%),"
    case "Light":
      return "linear-gradient(to right bottom, rgba(8,72,106, 0.25) 1%, rgba(139,175,154, 0.25)  47%, rgba(70,123,128, 0.25) 99%),"
    case "coverleft":
      return "linear-gradient(90deg, rgba(4,73,107,0.6) 32%, rgba(59,115,124,0.65) 48%, rgba(139,175,154,0.2) 79%, rgba(139,175,154,0.2) 100%),"
    case "coverright":
      return "linear-gradient(270deg, rgba(4,73,107,0.6) 32%, rgba(59,115,124,0.65) 48%, rgba(139,175,154,0.2) 79%, rgba(139,175,154,0.2) 100%),"
    case "coverwhite":
      return "linear-gradient(90deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.1) 32%, rgba(255,255,255,0.5) 48%, rgba(255,255,255,0.7) 79%, rgba(255,255,255,0.7) 100%),"
    case "covergray":
      return "linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 100%),"
    case "None":
      return ""
    default:
      return ""
  }
}

export const renderGradientImage = (param) => {
  return renderGradient(param).slice(0, -1)
}

export const renderBackground = (param) => {
  switch (param) {
    case "Gray":
      return "gray.100"
    case "White":
      return "white"
    default:
      return "transparent"
  }
}

import React from "react"
import { Flex, Text } from "@chakra-ui/core"

const renderTextColor = (param) => {
  switch (param) {
    case "green":
      return "white"
    case "yellow":
      return "white"
    case "blue":
      return "white"
    default:
      return "white"
  }
}

const Stat = ({ caption, value, color }) => {
  return (
    <Flex
      direction="column"
      justify="center"
      align={["center", "flex-start"]}
      pb={[4, 0]}
    >
      <Text color="white" fontSize="6xl" lineHeight="none">
        {value}
      </Text>
      <Text color={`${renderTextColor(color)}`} fontSize="xl">
        {caption}
      </Text>
    </Flex>
  )
}

export default Stat

import React from "react"
import { flatListToHierarchical } from "../../utils/flat-to-hierarchical"
import { Box, Stack, StackDivider } from "@chakra-ui/core"
import { useMenus } from "../../hooks/use-menus"
import MenuLink from "./menulink"

const MenuBox = ({ data, depth = 0 }) => {
  return (
    <Stack
      as="nav"
      borderTop={1}
      borderTopColor="green.500"
      divider={depth === 0 ? <StackDivider borderColor="green.500" /> : ""}
      spacing={2}
      key={`stack${depth}${data.id}`}
      align="stretch"
      pos={depth === 0 ? "relative" : "relative"}
      fontWeight="400"
      fontSize="md"
    >
      {data.map((m) => {
        const { children = [] } = m
        return (
          <Box
            pl={depth === 0 ? 0 : 0}
            color={depth === 0 ? "primary" : "secondary"}
            fontWeight={depth === 0 ? "500" : "400"}
            key={`box${depth}${m.id}`}
          >
            {children.length > 0 ? (
              <Box>
                <MenuLink item={m} depth={depth} size="base" />
                {/* 
                {depth === 0 ? (
                  <ChevronDownIcon ml={2} />
                ) : (
                  <ChevronRightIcon ml={2} />
                  )}
                */}
                <MenuBox
                  data={children}
                  depth={depth + 1}
                  key={`mbox${depth}${m.id}`}
                />
              </Box>
            ) : (
              <Box>
                <MenuLink item={m} depth={depth} size="base" />
              </Box>
            )}
          </Box>
        )
      })}
    </Stack>
  )
}

export default ({ id }) => {
  const { allWpMenu } = useMenus()
  const wpMenu = allWpMenu.nodes.filter(
    (node) => node.slug === "primary-menu"
  )[0]

  const hierarchicalList = flatListToHierarchical(wpMenu.menuItems.nodes)

  let submenu = []

  for (var main of hierarchicalList) {
    let string = JSON.stringify(main)
    if (string.includes(`${id}`)) {
      /**
       * we have the correct branch, now we need to find the stem that contains the current page
       * **/

      if (main.connectedNode.node.id === id) {
        main.active = true
        main.currentPage = true
      }

      if (main.children) {
        for (var branch of main.children) {
          if (branch.connectedNode.node.id === id) {
            branch.active = true
            branch.currentPage = true
          }
          if (branch.children) {
            for (var stem of branch.children) {
              if (stem.connectedNode.node.id === id) {
                branch.active = true
                stem.active = true
                stem.currentPage = true
              }
            }
          }
        }
        submenu = main.children

        break
      }
    }
  }

  return !!wpMenu &&
    !!wpMenu.menuItems &&
    !!wpMenu.menuItems.nodes &&
    !!submenu ? (
    <MenuBox data={submenu} key={wpMenu.id} />
  ) : null
}

import React from "react"
import { Box } from "@chakra-ui/core"
import { ReactComponent as Left } from "../../assets/svg/left.inline.svg"
import { ReactComponent as Right } from "../../assets/svg/right.inline.svg"

const Separator = ({ showOnTheRight }) => {
  return (
    <Box
      w="xs"
      color="yellow.500"
      pos="absolute"
      right={showOnTheRight ? "0" : "auto"}
      mt={["-10", "-10", "-20"]}
      maxWidth={[
        showOnTheRight ? "40" : "20",
        showOnTheRight ? "40" : "20",
        showOnTheRight ? "64" : "32",
      ]}
      maxHeight={["10", "10", "xs"]}
      zIndex="15"
    >
      {showOnTheRight ? <Right w="100%" /> : <Left w="100%" />}
    </Box>
  )
}

export default Separator

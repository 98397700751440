import React from "react"
import { StylesProvider, useMultiStyleConfig, useStyles } from "@chakra-ui/core"
import { Box, Flex } from "@chakra-ui/core"
import { Icon } from "../../utils/icons"

function StyledCard(props) {
  const { size, variant, children, ...rest } = props

  const styles = useMultiStyleConfig("Card", { size, variant })

  return (
    <Box sx={styles.card} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

function Title(props) {
  const styles = useStyles()
  return <Box sx={styles.title} {...props} />
}

function Content(props) {
  const styles = useStyles()
  return <Box sx={styles.content} {...props} />
}

function Iconbox(props) {
  const styles = useStyles()
  return <Box sx={styles.iconbox} {...props} />
}

const Card = ({ title, content, icon, flagged, style, context }) => {
  return (
    <StyledCard size="sm">
      <Flex
        pos="absolute"
        justify="center"
        align="center"
        zIndex="5"
        top="0"
        right="0"
        left="0"
        bottom="0"
        px={8}
        py={10}
      >
        <Flex mr="4" direction={["column", "row"]}>
          {icon !== "none" && (
            <Iconbox mr="4" color="white">
              <Icon icon={icon} fontSize="3x" />
            </Iconbox>
          )}
          <Flex direction="column">
            {title && <Title>{title}</Title>}
            <Content>{content}</Content>
          </Flex>
        </Flex>
      </Flex>
    </StyledCard>
  )
}

export default Card

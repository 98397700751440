import React from "react"
import { flatListToHierarchical } from "../../utils/flat-to-hierarchical"
import { List, ListItem } from "@chakra-ui/core"
import { useStyleConfig } from "@chakra-ui/core"
import { useMenus } from "../../hooks/use-menus"
import MenuLink from "./menulink"

function StyledMenuBox(props) {
  const { size, variant, ...rest } = props

  const styles = useStyleConfig("Menu", { size, variant })

  return <List sx={styles} {...rest} />
}

const MenuBox = ({ data, depth = 0, ...rest }) => {
  return (
    <StyledMenuBox
      pos={depth === 0 ? "relative" : "absolute"}
      d={depth === 0 ? "flex" : "none"}
      flexWrap="wrap"
      fontWeight="400"
      justifyContent="flex-end"
      alignItems="center"
      {...rest}
    >
      {data.map((m) => {
        const { children = [] } = m
        return (
          <ListItem ml="0" px={depth === 0 ? 6 : 0} key={m.id}>
            {children.length > 0 ? (
              <React.Fragment>
                <MenuLink item={m} depth={depth} />
                <MenuBox data={children} depth={depth + 1} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <MenuLink item={m} depth={depth} />
              </React.Fragment>
            )}
          </ListItem>
        )
      })}
    </StyledMenuBox>
  )
}

const Menu = ({ menulocation = "PRIMARY", ...rest }) => {
  const { allWpMenu } = useMenus()

  let uppermenu = menulocation.toUpperCase()

  let menuname =
    uppermenu !== "PRIMARY" ? `${uppermenu}_NAVIGATION` : "PRIMARY_NAVIGATION"

  let wpMenu = allWpMenu.nodes.filter((node) =>
    node.locations.includes(menuname)
  )[0]

  const hierarchicalList = flatListToHierarchical(wpMenu?.menuItems.nodes)

  return !!wpMenu &&
    !!wpMenu.menuItems &&
    !!wpMenu.menuItems.nodes &&
    !!hierarchicalList ? (
    <MenuBox data={hierarchicalList} {...rest} />
  ) : null
}

export default Menu

import React from "react"
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/core"
import { StylesProvider, useMultiStyleConfig, useStyles } from "@chakra-ui/core"
import { BlocksBuilder } from "../../components/PageBuilder"
import BackgroundImage from "gatsby-background-image"
import { renderGradientImage } from "../../utils/render-gradient"
import { backgroundDarkness } from "../../utils/background-darkness"
//import { useHeaderStyleActionsContext } from "../../context/header-style"

const renderAlign = (param) => {
  switch (param) {
    case "Left":
      return "row"
    case "Right":
      return "row-reverse"
    default:
      return "row"
  }
}

function StyledHero(props) {
  const { size, variant, children, ...rest } = props

  const styles = useMultiStyleConfig("Hero", { size, variant })

  return (
    <Box as="section" className="hero">
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

function HeroContainer(props) {
  const styles = useStyles()
  return <Container sx={styles.herocontainer} {...props} />
}

function Content(props) {
  const styles = useStyles()
  return <Box sx={styles.content} {...props} />
}

function Bgimage(props) {
  const { children } = props
  const styles = useStyles()
  return (
    <BackgroundImage sx={styles.bgimage} {...props}>
      {children}
    </BackgroundImage>
  )
}

const Hero = ({
  blockItems,
  image,
  title,
  content,
  gradient,
  align,
  order,
}) => {
  //const setHeaderStyle = useHeaderStyleActionsContext()
  const imageData = image?.localFile.childImageSharp
    ? image?.localFile.childImageSharp.fluid
    : image?.localFile.extension === "svg"
    ? image?.localFile.publicURL
    : ""
  const bgclass = backgroundDarkness(gradient, image?.darkness)

  return (
    <StyledHero className={`hero`}>
      <Bgimage
        Tag={`section`}
        fluid={[renderGradientImage(gradient), imageData]}
        className="heroimage"
      >
        <HeroContainer className={`herocontainer`}>
          <Flex
            direction={{
              sm: "column",
              md: `${renderAlign(align)}`,
            }}
            maxW={["340px", "100%"]}
          >
            <Content
              w={["340px", "100%", "100%", "7/12"]}
              className={`${bgclass}`}
            >
              <Heading mb="2" lineHeight="shorter">
                {title}
              </Heading>
              <Text fontSize="3xl" lineHeight="shorter">
                {content}
              </Text>
              {!!blockItems && (
                <BlocksBuilder context="hero" blocks={blockItems} />
              )}
            </Content>
            <Box
              display={["none", "none", "block"]}
              w={["0px", "0px", "1/3"]}
            ></Box>
          </Flex>
        </HeroContainer>
      </Bgimage>
    </StyledHero>
  )
}

export default Hero

import React, { useState, useEffect } from "react"
import { Box, Flex, Image } from "@chakra-ui/core"
import { Link } from "gatsby"
import { useStyleConfig } from "@chakra-ui/core"
import Menu from "./navigation/menu"
import Sidebar from "../components/navigation/sidebar"
import Social from "../components/navigation/social"
import Logo from "../assets/images/vivvo_converge_logo_colour.svg"
/*
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  useHeaderStyleContext,
  useHeaderStyleActionsContext,
} from "../context/header-style"
*/

function StyledHeader(props) {
  const { size, variant, ...rest } = props

  const styles = useStyleConfig("Header", { size, variant })

  return <Box sx={styles} {...rest} />
}

function Header({ data }) {
  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollTopLast, setScrollTopLast] = useState(0)
  const [headerPos] = useState("fixed")
  const [headerClass, setHeaderClass] = useState("")

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(window.pageYOffset || e.target.documentElement.scrollTop)
      setScrolling(e.target.documentElement.scrollTop < scrollTop)
      if (scrollTop < 300) {
        setHeaderClass("")
      }
      if (scrollTop < 0) {
        return
      }
      if (Math.abs(scrollTop - scrollTopLast) < 300) {
        return
      }
      if (scrolling) {
        setHeaderClass("")
        //if (navStatic === false) {
        //setHeaderPos("fixed")
        //}
        //setHeaderClass("")
      } else {
        setHeaderClass("hidden")
      }
      setScrollTopLast(scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  })
  return (
    <StyledHeader
      px={[8, 8, 16]}
      py={[8, 8]}
      pos={headerPos}
      top="0"
      zIndex="40"
      w="100%"
      maxW={["100%"]}
      bg="white"
      as="header"
      className={headerClass}
    >
      <Flex justify="flex-end" display={["none", "none", "none", "flex"]}>
        <Flex
          mt=""
          mb="4"
          align="center"
          alignItems="center"
          justifyContent="center"
        >
          <Menu menulocation="utility" size="small" mr="4" />
          <Social></Social>
        </Flex>
      </Flex>
      <Flex
        justify="space-between"
        align={["center", "center", "flex-end"]}
        wrap="nowrap"
      >
        <Box alignSelf={["center", "center", "center", "flex-start"]}>
          <Link to="/">
            <Image
              ignoreFallback
              src={Logo}
              w={[32, 32, 48]}
              minW={[32, 32, 48]}
              zIndex="48"
            />
          </Link>
        </Box>
        <Flex w="10"></Flex>
        <Flex
          display={{
            base: "none",
            sm: "flex",
          }}
          justify="flex-end"
        >
          <Menu display={["none", "none", "none", "flex"]} />
        </Flex>
        <Sidebar zIndex="45"></Sidebar>
      </Flex>
    </StyledHeader>
  )
}

export default Header

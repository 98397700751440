import { extendTheme } from "@chakra-ui/core"
import styles from "./styles.js"
import { fontsConfig, fontSizes, colors, sizes } from "./theme.js"
import breakpoints from "./breakpoints"
import Button from "./components/button"
import Card from "./components/card"
import Heading from "./components/heading"
import Menu from "./components/menu"
import Sidebar from "./components/sidebar"
import Pageheader from "./components/pageheader"
import Header from "./components/header"
import Footer from "./components/footer"
import Hero from "./components/hero"
import Social from "./components/social"
import Fullwidth from "./components/fullwidth"
import Halfwidth from "./components/halfwidth"
import Thirdwidth from "./components/thirdwidth"

const overrides = {
  styles: styles,
  fonts: fontsConfig,
  fontSizes: fontSizes,
  colors: colors,
  sizes: sizes,
  breakpoints: breakpoints,
  components: {
    Button,
    Card,
    Heading,
    Menu,
    Sidebar,
    Pageheader,
    Header,
    Footer,
    Social,
    Hero,
    Fullwidth,
    Halfwidth,
    Thirdwidth,
  },
}

export default extendTheme(overrides)

/*


// Global style overrides

// Foundational style overrides

// Component style overrides



*/

import React from "react"
import { Box } from "@chakra-ui/core"

const boxWidth = (narrow) => {
  if (narrow) {
    return "2/3"
  } else {
    return "100%"
  }
}

const Wysiwyg = ({ wysiwygField, narrow }) => {
  return (
    <Box
      className="prose"
      dangerouslySetInnerHTML={{ __html: wysiwygField }}
      w={["100%", "100%", `${boxWidth(narrow)}`]}
      maxW={["300px", "100%"]}
    ></Box>
  )
}

export default Wysiwyg

import React from "react"
import { Box, Container, Flex } from "@chakra-ui/core"
import { StylesProvider, useMultiStyleConfig, useStyles } from "@chakra-ui/core"
import Breadcrumb from "./navigation/breadcrumb"
import BackgroundImage from "gatsby-background-image"
import { renderGradientImage } from "../utils/render-gradient"
import { backgroundDarkness } from "../utils/background-darkness"
import abstractbg from "../assets/svg/abstract_bg.svg"

function StyledPageheader(props) {
  const { size, variant, children, ...rest } = props

  const styles = useMultiStyleConfig("Pageheader", { size, variant })

  return (
    <Box sx={styles.header} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

function StyledBreadcrumb(props) {
  const styles = useStyles()
  return (
    <Box sx={styles.breadcrumb} {...props}>
      <Breadcrumb {...props} />
    </Box>
  )
}

function Spacer(props) {
  const styles = useStyles()
  return <Box sx={styles.spacer} {...props} />
}

function StyledDate(props) {
  const styles = useStyles()
  return <Box sx={styles.date} {...props} />
}

export default function PageHeader({ type, data }) {
  const { id, title, date } = data
  const featured = data.featuredImage?.node ? data.featuredImage.node : null

  const publishedDate = new Date(date)
  const published = publishedDate.toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
  const gradient = featured ? "Full" : "None"
  const imageData = featured?.localFile.childImageSharp
    ? featured?.localFile.childImageSharp.fluid
    : featured?.localFile.extension === "svg"
    ? featured?.localFile.publicURL
    : `url(` + abstractbg + `)`

  const bgclass = backgroundDarkness(gradient, featured?.darkness)
  return (
    <Flex pos="relative" overflow="hidden" justify="start" align="center">
      <StyledPageheader w="100%" variant={bgclass} size={type}>
        <BackgroundImage
          Tag={`section`}
          fluid={[renderGradientImage(gradient), imageData]}
        >
          <Container maxW="xl">
            <Flex zIndex="10" pos="relative" py="32">
              <Spacer width="sidebar"></Spacer>
              <Flex direction="column">
                <Box
                  as="h1"
                  mb="0"
                  pb="0"
                  pos="relative"
                  _after={{
                    content: '" "',
                    pos: "absolute",
                    height: "1",
                    width: "24",
                    top: -5,
                    left: 0,
                    bg: "yellow.500",
                  }}
                >
                  {title}
                </Box>
                <StyledDate>{published}</StyledDate>
              </Flex>
            </Flex>
          </Container>
          <StyledBreadcrumb id={id} />
        </BackgroundImage>
      </StyledPageheader>
    </Flex>
  )
}

/*

export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "bg/header.jpg" }) {
            childImageSharp {
              fluid(quality: 90) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      `}
      render={(data) => <PageHeader data={data} {...props} />}
    />
  )
}
*/

export default {
  // The styles all button have in common
  baseStyle: {
    a: {
      color: "primary",
      pb: "0",
      lineHeight: "1rem",
      "&.active": {
        borderBottomWidth: 2,
        borderColor: "primary",
      },
      "&:hover": {
        color: "green.300",
      },
    },
    span: {
      fontSize: "22px",
    },
    li: {
      display: "flex",
      alignItems: "flex-end",
      py: "1",
      _first: {
        pl: "0",
        ml: "0",
      },
      _last: {
        pr: "0",
        mr: "0",
      },
    },
  },
  sizes: {
    small: {
      span: {
        fontSize: "xs",
        textTransform: "uppercase",
      },
      li: {
        px: "2",
        py: "0",
        my: "0",
      },

      a: {
        py: ".1rem",
        px: "2",
        bgColor: "green.50",
        borderRadius: "4px",
        border: "1px",
        borderStyle: "solid",
        borderColor: "green.50",
        borderBottomWidth: 0,
        "&.active": {
          borderBottomWidth: 1,
          borderColor: "green.50",
        },
      },
    },
  },
  variants: {
    dark: {
      a: {
        color: "white",
        "&.active": {
          borderBottomWidth: 0,
          color: "white",
        },
        "&:hover": {
          color: "gray.200",
        },
      },
    },
    mobile: {
      d: "flex",
      flexDirection: "column",
      a: {
        span: {
          fontSize: "lg",
        },
      },
      li: {
        px: [2, 2, 8],
        pb: "6",
        _first: {
          px: [2, 2, 8],
        },
        _last: {
          px: [2, 2, 8],
        },
      },
    },
  },
}

import { useStaticQuery, graphql } from "gatsby"

/* This should be a use-menu item and pass in the eq */
export const useMenus = () => {
  const { allWpMenu, wp } = useStaticQuery(
    graphql`
      query AllMenus {
        allWpMenu {
          nodes {
            slug
            name
            id
            locations
            menuItems {
              nodes {
                label
                id
                url
                parentId
                connectedNode {
                  node {
                    ... on WpContentNode {
                      uri
                      id
                    }
                  }
                }
              }
            }
          }
        }
        wp {
          generalSettings {
            url
          }
        }
      }
    `
  )
  return { allWpMenu: allWpMenu, wp: wp }
}

export default {
  baseStyle: {
    minHeight: "400px",
    "&.dark .prose": {
      "h3, h4, h5, p, li, strong": {
        color: "white",
      },
    },
  },
  variants: {
    card: {
      clipPath:
        "polygon(100% 0, 100% calc(100% - 140px), calc(100% - 140px) 100%, 0 100%, 0 0)",
    },
  },
}

import React from "react"
import { Box, Container } from "@chakra-ui/core"
import AllSectionLayouts from "./AllSectionLayouts"
import BackgroundImage from "gatsby-background-image"
import {
  renderGradientImage,
  renderBackground,
} from "../../utils/render-gradient"
import { backgroundDarkness } from "../../utils/background-darkness"
const sectionMaxWidth = (fullwidth) => {
  if (fullwidth) {
    return "100%"
  }
  return ["100%", "sm", "md", "lg", "xxl"]
}

const Section = ({ image, gradient, sectionLayouts }) => {
  const imageData = image?.localFile.childImageSharp
    ? image?.localFile.childImageSharp.fluid
    : image?.localFile.extension === "svg"
    ? `url(` + image?.localFile.publicURL + `)`
    : ""
  const bgclass = backgroundDarkness(gradient, image?.darkness)
  return (
    <Box bgColor={renderBackground(gradient)}>
      <BackgroundImage
        Tag={`section`}
        fluid={[renderGradientImage(gradient), imageData]}
        className={`section ${bgclass}`}
      >
        {sectionLayouts?.map((layout, index) => {
          return (
            <Container
              px={0}
              py={24}
              maxW={sectionMaxWidth(layout.fullwidth)}
              key={index}
            >
              <AllSectionLayouts layoutData={layout} bgclass={bgclass} />
            </Container>
          )
        })}
      </BackgroundImage>
    </Box>
  )
}

export default Section

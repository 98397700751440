/**
 * Parses a menu item object and returns Gatsby-field URI.
 *
 * @param {object} menuItem a single menu item
 * @param wordPressUrl
 * @param blogURI
 */

import useSiteMetadata from "../hooks/use-site-metadata"

export const CreateLocalLink = (menuItem, wordPressUrl) => {
  const sitedata = useSiteMetadata()
  const { blogSlug } = sitedata.themeOptions
  let { url, connectedNode } = menuItem

  if (url === "#") {
    return null
  }

  /**
   * Always want to pull of our API URL.
   */
  let newUri = url.replace(wordPressUrl, "")

  let sageWordPressUrl = wordPressUrl.replace("/wp", "")
  newUri = url.replace(sageWordPressUrl, "")

  /**
   * If it's a blog link, respect the users blogURI setting.
   */
  if (connectedNode && connectedNode.node.__typename === "WpPost") {
    newUri = "/" + blogSlug + newUri
  }

  return newUri
}

export const CreateLocalLinkFromUrl = (url, wordPressUrl) => {
  if (url === "#") {
    return null
  }

  /**
   * Always want to pull of our API URL.
   */
  let newUri = url.replace(wordPressUrl, "")

  let sageWordPressUrl = wordPressUrl.replace("/wp", "")
  newUri = url.replace(sageWordPressUrl, "")

  return newUri
}

export default {
  parts: ["header", "spacer", "title", "date", "breadcrumb"],
  baseStyle: {
    header: {
      bgSize: "cover",
      ".dark": {
        color: "white",
      },
    },
    spacer: {
      d: ["none", "none", "block"],
    },
    breadcrumb: {},
    date: {
      d: "none",
      color: "white",
    },
  },
  sizes: {
    post: {
      header: {},
      breadcrumb: {
        d: "none",
      },
      date: {
        d: "block",
        mt: "6",
      },
    },
  },
  variants: {
    dark: {
      header: {
        h1: {
          color: "white",
        },
      },
      spacer: {
        d: ["none", "none", "none"],
      },
    },
  },
}

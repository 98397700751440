import React from "react"
import { SimpleGrid } from "@chakra-ui/core"
import Card from "../Card"

const CardRepeater = ({ cards, style }) => {
  return (
    <SimpleGrid
      columns={{
        xs: 1,
        md: 1,
        lg: 2,
        xl: 3,
      }}
      spacing={[2, 2, 10]}
      mt={[4, 4, 16]}
    >
      {cards.map((card, i) => {
        return (
          <Card
            key={i}
            title={card.title}
            content={card.content}
            icon={card.icon}
            flagged={card.flagged}
            style={style}
          />
        )
      })}
    </SimpleGrid>
  )
}

export default CardRepeater

import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/core"
import { Box, Flex } from "@chakra-ui/core"

const AccordionBlock = ({ items }) => {
  return (
    <Box>
      <Accordion boxShadow="md" defaultIndex={[0]} allowMultiple>
        {items.map((item, i) => {
          return (
            <AccordionItem key={i}>
              <AccordionButton
                color="green.300"
                borderStyle="solid"
                borderBottom="1"
                borderBottomWidth="thin"
                borderLeftWidth="medium"
                borderLeftColor="white"
                borderBottomColor="gray.300"
                p="6"
                _expanded={{
                  bg: "gray.50",
                  color: "primary",
                  borderBottom: 0,
                  borderLeftWidth: "medium",
                  borderLeftColor: "yellow.500",
                  pb: 0,
                }}
              >
                <Box flex="1" textAlign="left">
                  {item.title}
                </Box>
                <Flex
                  bg="white"
                  border="1"
                  borderWidth="thin"
                  borderRadius="50%"
                  p="2"
                >
                  <AccordionIcon />
                </Flex>
              </AccordionButton>
              <AccordionPanel
                p="6"
                pt="2"
                bg="gray.50"
                borderLeftWidth="medium"
                borderLeftColor="yellow.500"
                dangerouslySetInnerHTML={{ __html: item.content }}
              ></AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </Box>
  )
}

export default AccordionBlock

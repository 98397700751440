export default {
  global: {
    fontFamily: "body",
    "html, body, #___gatsby, #gatsby-focus-wrapper": {
      height: "100%",
      minH: "100%",
    },
    "html, body": {
      color: "gray.700",
      fontFamily: "body",
      fontWeight: "light",
    },
    header: {
      gridArea: "header",
    },
    main: {
      gridArea: "body",
    },
    footer: {
      gridArea: "footer",
    },
    "h1,h2,h3,h4,h5": {
      color: "primary",
      fontWeight: "300",
    },
    h1: {
      fontSize: "7xl",
      lineHeight: "none",
      mb: "10",
    },
    h2: {
      fontSize: "5xl",
      lineHeight: "none",
      mb: [4, 4, 4],
    },
    h3: {
      fontSize: "4xl",
      fontWeight: "300",
      lineHeight: "short",
      mb: "6",
    },
    h4: {
      fontSize: "3xl",
      fontWeight: "300",
      lineHeight: "short",
      mb: "4",
    },
    h5: {
      fontSize: "base",
      fontWeight: "700",
      lineHeight: "short",
      mb: "2",
    },
    li: {
      mb: "2",
      ml: "6",
    },
    p: {
      lineHeight: "short",
    },
    a: {
      color: "blue.500",
    },
    "input[type=submit]": {
      bgColor: "yellow.400",
      fontSize: "sm",
      width: "auto",
    },
    ".prose": {
      h3: {
        fontWeight: "300",
        mb: "4",
      },
      p: {
        mb: "4",
        fontSize: "xl",
        lineHeight: "short",
      },
      li: {
        mb: "2",
        ml: "6",
        fontSize: "xl",
      },
      strong: {
        color: "blue.500",
        fontWeight: "400",
      },
      label: {
        color: "white",
        fontSize: "xl",
      },
      ".hbspt-form": {
        maxWidth: "500px",
        p: {
          m: "0",
          p: "0",
        },
        "ul.no-list": {
          listStyle: "none",
          clear: "both",
          li: {
            ml: "0",
            fontSize: "xs",
          },
        },
        label: {
          fontSize: "sm",
          fontWeight: "400",
          mb: "1",
          display: "block",
        },
        input: {
          borderRadius: "5px",
          color: "primary",
          py: "2",
          px: "2",
          width: "100%",
        },
        fieldset: {
          mb: "3",
          "div.field": {
            width: "100%",
          },
          textarea: {
            borderRadius: "5px",
            minHeight: "100px",
            mr: "10",
            width: "100%",
          },
          "&.form-columns-2": {
            "div.field": {
              width: "50%",
              float: "left",
              mb: "2",
              "div.input": {
                mr: "4",
              },
            },
          },
          "&.form-columns-1": {
            mr: "4",
          },
        },
        ".hs_recaptcha": {
          mb: "6",
        },
        ".hs_submit": {
          mr: "4",
          input: {
            borderRadius: "0px",
            px: "8",
            py: "2",
            width: "auto",
          },
        },
        ".hubspot-link__container": {
          display: "none",
        },
        ".hs-richtext": {
          fontSize: "md",
          p: {
            fontSize: "md",
          },
        },
      },
    },
    ".layouts > .hero": {
      h2: {
        fontSize: ["4xl", "4xl", "7xl"],
        lineHeight: "none",
      },
    },
    ".layouts > .hero ~ .hero": {
      py: "0",
      h2: {
        fontSize: ["4xl", "4xl", "6xl"],
      },
      ".herocontainer": {
        py: "32",
      },
    },
    ".fullw h2, .thirds h2": {
      pos: "relative",
    },
    ".fullw h2::after, .thirds h2::after": {
      content: '""',
      pos: "absolute",
      height: "1",
      width: "24",
      top: "-5",
      left: "0",
      bg: "yellow.500",
    },
    ".fullw .white h2::after, .thirds .white h2::after": {
      display: "none",
    },
  },
}

import React from "react"
import { Box, Container, Flex, Grid } from "@chakra-ui/core"
import Header from "../components/header"
import Footer from "../components/footer"
import SiteMetadata from "../components/site-metadata"

const Layout = ({ children, data, id }) => {
  return (
    <Grid
      templateRows="auto 1fr auto"
      templateAreas="'header' 'body' 'footer'"
      minH="100%"
      height="100vh"
      id={id}
    >
      <SiteMetadata />
      <Header area="header" />
      <Container maxW="none" px="0" pt="32" as="main">
        <Flex>
          <Box flex="1" className="layouts">
            {children}
          </Box>
        </Flex>
      </Container>
      <Footer area="footer" />
    </Grid>
  )
}

export default Layout

export default {
  // The styles all button have in common
  parts: ["button", "iconbox", "icon", "label"],
  baseStyle: {
    button: {
      bgColor: "yellow.100",
      fontWeight: "400",
      color: "green.500",
      px: "4",
      py: "2",
      mt: "4",
    },
    iconbox: {
      color: "green.500",
      mr: "2",
    },
    icon: {
      fontSize: "sm",
    },
  },
  // Two variants: outline and solid
  variants: {
    dark: {
      border: "2px solid",
      borderColor: "green.500",
    },
  },
}

export default {
  baseStyle: {
    "&.dark h2,&.dark h4": {
      color: "white",
      marginBottom: 0,
      "&::after": {
        display: "none",
      },
    },
  },
}

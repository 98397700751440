import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"

import { faKey } from "@fortawesome/pro-light-svg-icons/faKey"
import { faFingerprint } from "@fortawesome/pro-light-svg-icons/faFingerprint"
import { faSignInAlt } from "@fortawesome/pro-light-svg-icons/faSignInAlt"
import { faUserShield } from "@fortawesome/pro-light-svg-icons/faUserShield"
import { faMap } from "@fortawesome/pro-light-svg-icons/faMap"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons/faArrowRight"
import { faTypewriter } from "@fortawesome/pro-light-svg-icons/faTypewriter"
import { faRadio } from "@fortawesome/pro-light-svg-icons/faRadio"
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck"

import { faRadiationAlt } from "@fortawesome/pro-regular-svg-icons/faRadiationAlt"

import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin"

library.add(
  faKey,
  faFingerprint,
  faSignInAlt,
  faUserShield,
  faMap,
  faArrowRight,
  faTypewriter,
  faRadiationAlt,
  faRadio,
  faCheck,
  faTwitter,
  faLinkedin
)

const iconRotate = (icon) => {
  switch (icon) {
    case "keyicon":
      return -45
    default:
      return 360
  }
}

const iconName = (icon) => {
  switch (icon) {
    case "keyicon":
      return "key"
    default:
      return icon
  }
}

const iconStyle = (icon) => {
  switch (icon) {
    case "radiation-alt":
      return "far"
    case "twitter":
      return "fab"
    case "linkedin":
      return "fab"
    default:
      return "fal"
  }
}

export const Icon = (props) => {
  let { icon, fontSize = "sm" } = props
  if (props.sx) {
    ;({ fontSize } = props.sx)
  }
  return (
    <FontAwesomeIcon
      icon={[`${iconStyle(icon)}`, `${iconName(icon)}`]}
      transform={{ rotate: `${iconRotate(icon)}` }}
      size={fontSize}
      fixedWidth
    />
  )
}

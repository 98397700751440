import React from "react"
import useSiteMetadata from "../../hooks/use-site-metadata"
import { Box, Stack, useStyleConfig } from "@chakra-ui/core"
import { Icon } from "../../utils/icons"
import UniversalLink from "../../utils/UniversalLink"

function StyledSocial(props) {
  const { size, variant, ...rest } = props

  const styles = useStyleConfig("Social", { size, variant })

  return <Box sx={styles} {...rest} />
}

const Social = () => {
  const sitedata = useSiteMetadata()
  const { twitterUrl, linkedinUrl } = sitedata.themeOptions
  return (
    <StyledSocial>
      <Stack spacing={2} isInline color="primary">
        {twitterUrl && (
          <UniversalLink to={twitterUrl}>
            <Icon icon="twitter"></Icon>
          </UniversalLink>
        )}
        {linkedinUrl && (
          <UniversalLink to={linkedinUrl}>
            <Icon icon="linkedin"></Icon>
          </UniversalLink>
        )}
      </Stack>
    </StyledSocial>
  )
}

export default Social

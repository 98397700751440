import { useStaticQuery, graphql } from "gatsby"

/* This should be a use-menu item and pass in the eq */
export const useSiteUrl = () => {
  const { wp } = useStaticQuery(
    graphql`
      query SiteUrl {
        wp {
          generalSettings {
            url
          }
        }
      }
    `
  )
  return { wp: wp }
}

import React from "react"
import { Box, Container, Flex, Grid } from "@chakra-ui/core"
import Header from "../components/header"
import Footer from "../components/footer"
import PageHeader from "../components/page-header"
import PageMenu from "../components/navigation/page-menu"
import SiteMetadata from "../components/site-metadata"

const Layout = ({ children, data, id }) => {
  return (
    <Grid
      templateRows="auto 1fr auto"
      templateAreas="'header' 'body' 'footer'"
      minH="100%"
      height="100vh"
      id={id}
    >
      <SiteMetadata />
      <Header area="header" />
      <Box mb="32" as="main" pt="32">
        {data?.page && (
          <PageHeader data={data?.page ? data.page : data} type="page" />
        )}
        <Container maxW="xl">
          <Flex>
            <Box
              mt={10}
              pr={32}
              minW="sidebar"
              width="sidebar"
              d={["none", "none", "block"]}
            >
              {data?.page && (
                <PageMenu id={data?.page?.id ? data.page.id : ""} />
              )}
            </Box>
            <Box mt={10} mb={100} flex="1">
              {children}
            </Box>
          </Flex>
        </Container>
      </Box>
      <Footer area="footer" />
    </Grid>
  )
}

export default Layout

import React from "react"
import { Box, Flex, SimpleGrid } from "@chakra-ui/core"
import { useStyleConfig } from "@chakra-ui/core"
import { BlocksBuilder } from "../../../components/PageBuilder"
import {
  renderGradient,
  renderBackground,
} from "../../../utils/render-gradient"
import { backgroundDarkness } from "../../../utils/background-darkness"

const backgroundClass = (bgclass, transparentBackground) => {
  if (transparentBackground === true) {
    return bgclass
  } else {
    return "light"
  }
}

const gridSpacing = (fullwidth) => {
  if (fullwidth) {
    return 0
  }
  return [8, 8]
}

const gridPadding = (fullwidth) => {
  if (fullwidth) {
    return 0
  }
  return [0, 8, 16]
}

function StyledHalf(props) {
  const { size, variant, ...rest } = props

  const styles = useStyleConfig("Halfwidth", { size, variant })

  return <Flex sx={styles} {...rest} />
}

const HalfLayout = ({ columns, float, bgclass, fullwidth }) => {
  return (
    <SimpleGrid
      columns={[1, 1, 2]}
      spacing={gridSpacing(fullwidth)}
      px={gridPadding(fullwidth)}
      className={`half ${bgclass}`}
      w={["100%"]}
    >
      {columns.map((column, i) => {
        return (
          <StyledHalf
            bg={column?.transparentBackground ? "" : "white"}
            borderColor="yellow.500"
            borderLeftWidth={
              !column?.transparentBackground &&
              column?.columnBorder === true &&
              i === 1
                ? 5
                : 0
            }
            borderRightWidth={
              !column?.transparentBackground &&
              column?.columnBorder === true &&
              i === 0
                ? 5
                : 0
            }
            borderStyle="solid"
            py={12}
            px={column?.transparentBackground ? [8, 0] : [8, 12]}
            key={i}
            className={`half ${backgroundClass(
              bgclass,
              column?.transparentBackground
            )}`}
            variant={column?.cardStyle ? "card" : ""}
          >
            {!!column.blockItems && (
              <BlocksBuilder blocks={column.blockItems} />
            )}
          </StyledHalf>
        )
      })}
    </SimpleGrid>
  )
}
const HalfFullWidthLayout = ({ columns, float, bgclass, fullwidth }) => {
  return (
    <Flex
      direction={["column", "column", "row"]}
      justify={["center"]}
      className="halffull"
    >
      {columns.map((column, i) => {
        const { image, gradient } = column
        const imageData = image?.localFile.childImageSharp
          ? image?.localFile.childImageSharp.fluid.src
          : image?.localFile.extension === "svg"
          ? image?.localFile.publicURL
          : ""
        const bgclass = backgroundDarkness(gradient, image?.darkness)
        return (
          <StyledHalf
            w={["100%", "100%", "100%", "50%", "50%"]}
            bgImage={[`${renderGradient(gradient)} url(${imageData})`]}
            bgColor={renderBackground(gradient)}
            bgPos={i === 0 ? "center left" : "center right"}
            bgSize="cover"
            className={`section ${bgclass}`}
            justifyContent={i === 0 ? ["flex-start", "flex-end"] : "flex-start"}
            key={i}
          >
            <Box
              maxW={["350px", "100%", "100%", "lg", "xxlhalf"]}
              px={[8, 8, 20]}
              py={12}
            >
              {!!column.blockItems && (
                <Box>
                  <BlocksBuilder blocks={column.blockItems} />
                </Box>
              )}
            </Box>
          </StyledHalf>
        )
      })}
    </Flex>
  )
}

const Half = (props) => {
  const { fullwidth } = props

  const ComponentTag = fullwidth ? HalfFullWidthLayout : HalfLayout

  return <ComponentTag {...props} />
}

export default Half

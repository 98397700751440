import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/use-site-metadata"

const SiteMetadata = ({ pathname }) => {
  const sitedata = useSiteMetadata()
  //const { gtagCode, gtagCodeBody } = sitedata.themeOptions
  const { generalSettingsTitle } = sitedata.allSettings

  // Note: `location.href` isn't available on server-side so we must get it from `pathname`:
  // https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/#article-header-id-4

  return (
    <Helmet
      defer={true}
      defaultTitle={generalSettingsTitle}
      titleTemplate={`%s | ${generalSettingsTitle}`}
    >
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
    </Helmet>
  )
}

export default SiteMetadata

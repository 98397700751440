import React from "react"

import PageLayout from "../layouts/page"
import LandingLayout from "../layouts/landing"
import PostLayout from "../layouts/post"
import AllLayouts from "../acf-layouts/AllLayouts"
//import HeaderStyle from "../context/header-style"

function PageLayoutTemplate({ data }) {
  const { page } = data
  const { pageBuilder, uri, pageTemplate, nodeType } = page

  const pagelayouts = {
    page: PageLayout,
    landing: LandingLayout,
    post: PostLayout,
  }

  let tag = "page"

  tag = (uri === "/") | (pageTemplate === "landing") ? "landing" : "page"

  tag = nodeType === "Post" ? "post" : tag

  const PageLayoutTagName = pagelayouts[tag || "page"]

  let layouts = []

  if (pageBuilder && pageBuilder.layouts) {
    layouts = pageBuilder.layouts
  }

  return (
    <PageLayoutTagName data={data}>
      {layouts.map((layout, index) => {
        return <AllLayouts key={index} order={index} layoutData={layout} />
      })}
    </PageLayoutTagName>
  )
}

export default PageLayoutTemplate

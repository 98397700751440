import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import { useStyleConfig } from "@chakra-ui/core"
import { BlocksBuilder } from "../../../components/PageBuilder"

function StyledThird(props) {
  const { size, variant, ...rest } = props

  const styles = useStyleConfig("Thirdwidth", { size, variant })

  return <Box sx={styles} {...rest} />
}

const boxWidth = (float, boxnumber, widerSecond) => {
  switch (boxnumber) {
    case 0:
      if (widerSecond) {
        return "1/3"
      }
      return "2/3"
    case 1:
      if (float) {
        return "550px"
      }
      if (widerSecond) {
        return "2/3"
      }
      return "1/3"
    default:
      return "2/3"
  }
}

const innerWidth = (float, boxnumber) => {
  switch (boxnumber) {
    case 1:
      if (float) {
        return "550px"
      }
      break
    default:
      return "100%"
  }
}

const padRight = (float, i) => {
  if (float) {
    switch (i) {
      case 0:
        return "8rem"
      default:
        return "4rem"
    }
  } else {
    return "8rem"
  }
}

const padBottom = (float) => {
  if (float) {
    return "10rem"
  } else {
    return "0"
  }
}

const floatPos = (float, i) => {
  if (float) {
    switch (i) {
      case 1:
        return "absolute"
      default:
        return "relative"
    }
  }
  return "relative"
}

const Thirds = ({ columns, float, widerSecond }) => {
  return (
    <Flex
      direction={["column", "column", "row"]}
      pos="relative"
      pb={[0, 0, `${padBottom(float)}`]}
      className="thirds"
      w="100%"
    >
      {columns.map((column, i) => {
        return (
          <StyledThird
            bg={column?.transparentBackground ? "" : "white"}
            pl={[8, 8, 16]}
            pr={[8, 8, `${padRight(float, i, widerSecond)}`]}
            py={12}
            key={i}
            w={["100%", "100%", `${boxWidth(float, i, widerSecond)}`]}
            className={column?.transparentBackground ? "trans" : "white"}
            variant={column?.cardStyle ? "card" : ""}
          >
            {!!column.blockItems && (
              <Box
                right="0"
                bottom="-50"
                pos={["relative", "relative", `${floatPos(float, i)}`]}
                w={["100%", "100%", `${innerWidth(float, i, widerSecond)}`]}
              >
                <BlocksBuilder blocks={column.blockItems} />
              </Box>
            )}
          </StyledThird>
        )
      })}
    </Flex>
  )
}
export default Thirds

// src/components/AllLayouts.js

import React from "react"
import Thirds from "../Layouts/thirds"
import Half from "../Layouts/half"
import Fullwidth from "../Layouts/fullwidth"

const AllSectionLayouts = ({ layoutData, bgclass }) => {
  const layoutType = layoutData.fieldGroupName
  layoutData.bgclass = bgclass

  /**
   * Default component
   */
  const Default = () => (
    <div>
      In AllSectionLayouts the mapping of this component is missing:{" "}
      {layoutType}
    </div>
  )

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Thirds: Thirds,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Half: Half,
    page_Pagebuilder_Layouts_Sectionlayout_SectionLayouts_Fullwidth: Fullwidth,
    page_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType]
    ? layouts[layoutType]
    : layouts["page_default"]

  return <ComponentTag {...layoutData} />
}

export default AllSectionLayouts

// src/components/AllLayouts.js

import React from "react"
import Hero from "./Hero"
import Page from "./Page"
import Section from "./Section"
import Separator from "./Separator"

const AllLayouts = (props) => {
  const { layoutData, order } = props
  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => (
    <div>
      In AllLayouts the mapping of this component is missing: {layoutType}
    </div>
  )

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    page_Pagebuilder_Layouts_Herolayout: Hero,
    page_Pagebuilder_Layouts_Pagelayout: Page,
    post_Pagebuilder_Layouts_Pagelayout: Page,
    page_Pagebuilder_Layouts_Sectionlayout: Section,
    page_Pagebuilder_Layouts_Separator: Separator,
    page_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType]
    ? layouts[layoutType]
    : layouts["page_default"]

  return <ComponentTag order={order} {...layoutData} />
}

export default AllLayouts

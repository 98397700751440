export default {
  parts: ["bgimage", "content"],
  baseStyle: {
    bgimage: {
      ".heroimage": {
        py: ["12", "12", "32"],
      },
    },
    herocontainer: {
      px: [3, 6, 8, 12, 16],
      py: [32, 32, 32, 32, 56],
      w: ["lg", "xl", "xxl"],
      maxW: ["340px", "100%"],
      overflow: "hidden",
    },
    content: {
      h2: {
        fontSize: ["lg", "xl"],
      },
      "&.dark h2,&.dark h4,&.dark p": {
        color: "white",
      },
    },
  },
}
